import { DocumentTitle, MetaDescription, Sitename, PromocodeKey, PromocodeQuery } from "../components/constants"

export function SetMetaDescription(content?: string): void {
    if (!content) {
        content = MetaDescription
    }
    document.querySelector('meta[name="description"]')?.setAttribute('content', content)
}
export function SetDocumentTitle(title?: string, addMe: boolean = false): void {
    if (!title) {
        title = DocumentTitle
    }

    if (addMe) {
        title = `${title} | ${Sitename}`
    }
    document.title = title
}
export function SetDefaultMetaDescription(): void {
    SetMetaDescription(MetaDescription)
}
export function SetDefaultDocumentTitle(): void {
    SetDocumentTitle(DocumentTitle)
}

export function BuildExternalURL(url: string): string {
    if (!url) {
        return ''
    }

    const promoCodeSuffix = localStorage.getItem(PromocodeKey)
    if (promoCodeSuffix !== null) {
        url = GetUrlWithPromocode(url, promoCodeSuffix);
    }

    return encodeURI(url)
}

export function GetUrlWithPromocode(url: string, promoCodeSuffix: string): string {
    if (!url) {
        return ''
    }

    if (!promoCodeSuffix) {
        return url;
    }

    const _url = new URL(url);
    if (!_url.search) {
        url += '?'
    }
    if (_url.search.indexOf(PromocodeQuery) < 0) {
        url += `${PromocodeQuery}=${promoCodeSuffix.slice(1)}`
    } else {
        url += promoCodeSuffix
    }
    return url
}